import Logo from '@common/assets/images/app-logo.svg';
import Avatar1 from '@common/assets/images/avatar-default.svg';
import { routes } from '@common/utils/routes';
import { storage, storageKey } from '@common/utils/storage';
import Avatar, { AvatarSize } from '@components/Avatar/Avatar';
import axios from 'axios';

import Button, { ButtonType } from '@components/Button/Button';
import Icon, { ICONS, IconSize } from '@components/SVG/Icon';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import './NavBar.scss';

import { AuthType } from '@common/enum';
import { SocketContext } from '@common/utils/socket';
import { selectTotalUnreadMessage, selectUserType } from '@services/controllers/common/CommonSelector';
import { setTotalUnreadMessage, toggleUserType } from '@services/controllers/common/CommonSlice';
import { selectHasNewOrder } from '@services/controllers/order/OrderSelector';
import { userActions } from '@services/controllers/user/UserActions';
import { selectCurrentUser } from '@services/controllers/user/UserSelector';
import { useDispatch, useSelector } from 'react-redux';
import { exchangeActions } from '@services/controllers/exchange/ExchangeAction';
import LocalizedLink from '@components/LocalizeLink/LocalizeLink';
import LocalizedNavLink from '@components/NavLinkLocalize/NavLinkLocalize';
import useLocalizeNavigate from '@common/hooks/useLocalizeNavigate';
import { getPathName } from '@common/utils/helpers';
import UserDropdown from './components/UserDropdown/UserDropdown';

// Define all routes that have only logo in navbar
export const navbarOnlyLogoRoutes = [
  routes.LOGIN,
  routes.SIGN_UP,
  routes.FORGOT_PASSWORD,
  routes.ADMIN,
  routes.ADMIN_LOGIN,
  routes.ADMIN_BRANDS,
  routes.ADMIN_CAMPAIGNS,
  routes.ADMIN_INFLUENCERS,
];

export interface NavbarProps {
  className?: string;
}

const NavBar: React.FC<NavbarProps> = ({ className = '' }) => {
  const location = useLocation();
  const params = useParams<{ lang: string }>();
  const navigate = useLocalizeNavigate();

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const loginType = useSelector(selectUserType);
  const currentUser = useSelector(selectCurrentUser);
  const totalUnreadMessage = useSelector(selectTotalUnreadMessage);
  const hasNewOrder = useSelector(selectHasNewOrder);
  const currentSocket = React.useContext(SocketContext);
  const isShowBadge = loginType === AuthType.BRANDS && hasNewOrder;

  const [isShowMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isShowDropdown, setShowDropdown] = useState(false);

  const isShowOnlyLogo = useMemo(() => {
    const newNavbarOnlyLogoRoutes = [...navbarOnlyLogoRoutes];
    if (storage.isAdminLogged) {
      return false;
    }
    return !newNavbarOnlyLogoRoutes.includes(getPathName(location.pathname));
  }, [storage.isAdminLogged]);

  useEffect(() => {
    const changeBackground = () => {
      setScrolled(window.scrollY >= 60);
    };
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  useEffect(() => {
    currentSocket.on('send-total-unread-message', (totalUnreadMessage: number) => {
      dispatch(setTotalUnreadMessage(totalUnreadMessage));
    });
    return () => {
      currentSocket.off('send-total-unread-message');
    };
  }, []);

  useEffect(() => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  }, [location.pathname]);

  const memoIsEnLang = useMemo(() => {
    return i18n.language === 'en' || i18n.language === 'en-US';
  }, [i18n.language]);

  const onToggleMenu = () => setShowMenu(!isShowMenu);

  const onToggleDropdown = () => setShowDropdown(!isShowDropdown);

  const classes = classNames('navbar', className, {
    navbar__bg: scrolled || isShowMenu,
    navbar__scale: isShowMenu,
  });
  const menuClasses = classNames('navbar__menu--link', {
    'navbar__menu--show': isShowMenu,
  });

  const burgerClasses = classNames('navbar__burger--toggle', {
    'burger--active': isShowMenu,
  });
  const btnLoginInfluencerClasses = classNames('navbar__menu--register influencer-btn', {
    'en-btn': memoIsEnLang,
    'th-btn': i18n.language === 'th',
  });
  const btnLoginBrandClasses = classNames('navbar__menu--register', {
    'en-btn': memoIsEnLang,
    'th-btn': i18n.language === 'th',
  });
  const goToLoginPage = (type: AuthType) => {
    dispatch(toggleUserType(type));
    navigate(routes.LOGIN);
  };

  const goToCreateCampaignPage = () => {
    navigate(loginType === AuthType.BRANDS ? routes.CREATE_CAMPAIGN : routes.DEFAULT);
  };

  // Get userDetail and getSampleWorks for currencUser
  useEffect(() => {
    if (storage.isLoggedIn && getPathName(location.pathname) === routes.DEFAULT) {
      dispatch(userActions.getDetail());
    }
    if (
      storage.isLoggedIn &&
      loginType === AuthType.INFLUENCER &&
      currentUser &&
      currentUser.id &&
      [routes.MY_PROFILE, routes.PROFILE].includes(location.pathname)
    ) {
      dispatch(userActions.getSampleWorks(currentUser.id));
    }
  }, [storage.isLoggedIn]);

  // Get exchange rate when app start
  useEffect(() => {
    dispatch(exchangeActions.getExchangeRate());
  }, []);

  // useEffect check language user change in firsttime visit site
  useEffect(() => {
    if (params.lang) {
      if (storage.getToken() && currentUser && currentUser.lang !== params.lang) {
        dispatch(
          userActions.updateProfile({
            lang: params.lang,
            type: loginType,
          }),
        );
        i18n.changeLanguage(params.lang);
        dayjs.locale(params.lang);
      } else {
        i18n.changeLanguage(params.lang);
        dayjs.locale(params.lang);
      }
    }
  }, []);

  // TODO:add more condition here to show red dot on avatar

  const checkShowRedDotOnAvatar = () => {
    if (
      (loginType === AuthType.BRANDS && !!currentUser?.numberOfCampaignNotView && currentUser.numberOfCampaignNotView > 0) ||
      totalUnreadMessage > 0 ||
      isShowBadge
    ) {
      return true;
    }
    return false;
  };

  const handleClickChangeLanguage = () => {
    try {
      const nextLanguage: string = memoIsEnLang ? 'th' : 'en';
      if (storage.isLoggedIn) {
        dispatch(
          userActions.updateProfile({
            lang: nextLanguage,
            type: loginType,
          }),
        );
      } else {
        i18n.changeLanguage(nextLanguage);
      }
      dayjs.locale(nextLanguage);
      setTimeout(() => {
        navigate(getPathName(location.pathname), null, true, nextLanguage);
      }, 200);
    } catch (error) {
      console.log(error);
    }
  };

  const onLogout = (e: any) => {
    e.preventDefault();
    storage.removeToken();
    storage.removeStorageKey(storageKey.IS_NEW_USER);
    setTimeout(() => {
      navigate(routes.ADMIN_LOGIN, null, true);
      window.location.reload();
    }, 1000);
  };

  return (
    <nav className={classes}>
      <LocalizedLink className="navbar__logo" to={routes.DEFAULT}>
        <img src={Logo} alt="Navbar logo" />
      </LocalizedLink>
      {isShowOnlyLogo && (
        <>
          <div className="navbar__menu">
            {!storage.getToken() ? (
              <>
                <div className={menuClasses}>
                  <LocalizedNavLink exact activeClassName="menu-active" to={routes.ABOUT_US}>
                    {t('navbar.about')}
                  </LocalizedNavLink>
                  <LocalizedNavLink exact activeClassName="menu-active" to={routes.CONTACT}>
                    {t('navbar.contact')}
                  </LocalizedNavLink>
                  <div className="navbar__menu__language" onClick={handleClickChangeLanguage}>
                    <div className="navbar__menu__language-content">{t('userDropdown.language')}</div>

                    <Button className="navbar__menu__language-icon" buttonType={ButtonType.Outline}>
                      {memoIsEnLang ? 'EN' : 'TH'}
                    </Button>
                  </div>
                </div>
                <Button
                  className={btnLoginInfluencerClasses}
                  title={t('navbar.startAsInfluencer')}
                  buttonType={ButtonType.Outline}
                  onClick={() => goToLoginPage(AuthType.INFLUENCER)}
                />
                <Button
                  className={btnLoginBrandClasses}
                  title={t('navbar.startAsBrand')}
                  buttonType={ButtonType.Primary}
                  onClick={() => goToLoginPage(AuthType.BRANDS)}
                />
              </>
            ) : (
              <div className={menuClasses}>
                {loginType === AuthType.INFLUENCER ? (
                  <LocalizedNavLink exact activeClassName="menu-active" to={routes.BROWSE_BRAND}>
                    {t('navbar.browseBrand')}
                  </LocalizedNavLink>
                ) : null}

                {loginType === AuthType.BRANDS ? (
                  <LocalizedNavLink exact activeClassName="menu-active" to={routes.BROWSE_INFLUENCER}>
                    {t('navbar.browseInfluencer')}
                  </LocalizedNavLink>
                ) : null}
                <LocalizedNavLink exact activeClassName="menu-active" to={routes.MY_CAMPAIGN}>
                  {t('navbar.myCampaign')}
                </LocalizedNavLink>
                <Button
                  className="navbar__menu-button influencer-btn"
                  title={loginType === AuthType.BRANDS ? t('navbar.createCampaign') : t('navbar.browseCampaign')}
                  buttonType={ButtonType.Default}
                  onClick={goToCreateCampaignPage}
                />
                <UserDropdown
                  handleClickChangeLanguage={handleClickChangeLanguage}
                  isShowDropdown={isShowDropdown}
                  isShowBadge={isShowBadge}
                >
                  <Button className="navbar__menu-button profile-btn" buttonType={ButtonType.Outline} onClick={onToggleDropdown}>
                    <div className="navbar__menu-profile-btn">
                      <Avatar size={AvatarSize.SM} src={currentUser?.avatar || Avatar1} border isShowRedDot={checkShowRedDotOnAvatar()} />
                      <div className="navbar__menu-profile-name">{currentUser?.fullName || currentUser?.userName}</div>
                      <Icon name={ICONS.ANGLE_DOWN} size={IconSize.XS} rotation={isShowDropdown ? 180 : 0} />
                    </div>
                  </Button>
                </UserDropdown>
              </div>
            )}
          </div>
          {!storage.getToken() ? (
            <div className="navbar__burger" onClick={onToggleMenu}>
              <div id="menu" className={burgerClasses}>
                <span className="ico">
                  <span className="mask" />
                </span>
              </div>
            </div>
          ) : (
            <div className="navbar__profile">
              <UserDropdown handleClickChangeLanguage={handleClickChangeLanguage} isShowDropdown={isShowDropdown} isShowBadge={isShowBadge}>
                <Button className="navbar__menu-button profile-btn" buttonType={ButtonType.Outline} onClick={onToggleDropdown}>
                  <div className="navbar__menu-profile-btn">
                    <Avatar size={AvatarSize.SM} src={currentUser?.avatar || Avatar1} border isShowRedDot={checkShowRedDotOnAvatar()} />
                    <div className="navbar__menu-profile-name">{currentUser?.fullName || currentUser?.userName}</div>
                    <Icon name={ICONS.ANGLE_DOWN} size={IconSize.XS} rotation={isShowDropdown ? 180 : 0} />
                  </div>
                </Button>
              </UserDropdown>
            </div>
          )}
        </>
      )}

      {storage.isAdminLogged && (
        <div className="navbar_logout" onClick={onLogout}>
          <div className="navbar_logout-item ">
            <img src={ICONS.LOG_OUT} alt="" className="navbar_logout-item-icon" />
            <div className="navbar_logout-item-content">{t('userDropdown.logout')}</div>
          </div>
        </div>
      )}
      {!storage.isAdminLogged && (
        <Button className="navbar__language" onClick={handleClickChangeLanguage} buttonType={ButtonType.Outline}>
          {memoIsEnLang ? 'EN' : 'TH'}
        </Button>
      )}
    </nav>
  );
};

export default NavBar;
